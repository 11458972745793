@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #555;
  font-size: 14px;
  background-color: white;
}

.navbar {
  transition: all 0.5s ease !important;
}

.modal:not(dialog:not(.modal-open)),
.modal::backdrop {
  background-color: rgba(0, 0, 0, 0.85);
  animation: modal-pop 0.2s ease-out;
}

.tab-active {
  background-color: #ffd300;
}

.clip-left {
  clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 105%);
}

.clip-right {
  clip-path: polygon(0 5%, 100% 0, 100% 105%, 0 100%);
}

.btn-radio div label svg {
  width: 8px;
}

.hideDrop {
  visibility: hidden !important;
  opacity: 0 !important;
}
.prose
  :where(strong):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
  color: black !important;
}

@media only screen and (max-width: 500px) {
  .clip-left {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .clip-right {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}
.swiper-pagination{
  position: static !important;
  margin-top: 20px;
}